<template>
  <el-row :gutter="20" justify="center">
    <el-col :span="12">注册界面</el-col>
  </el-row>
  <el-row :gutter="20" justify="center">
    <el-col :span="6">
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="4" :offset="9">
      <el-input placeholder="请输入验证码" v-model="code"></el-input>
    </el-col>
    <el-col :span="2">
      <el-button type="info" style="width: 100%" @click="getCode">获取验证码</el-button>
    </el-col>
  </el-row>
  <el-row :gutter="20" justify="center">
    <el-col :span="6">
      <el-button type="primary" style="width:100%" @click="regedit">注册</el-button>
    </el-col>
  </el-row>
</template>

<script>

import axios from "axios";
axios.defaults.baseURL = 'http://www.gallilikelike.cn/api/';
export default {
  name: 'App',
  data() {
    return {
      phone:"",
      code:""
    }
  },
  methods:{
    getCode() {
      console.log("获取验证码");
      axios({
        method: "get",
        url: "/send",
        params:{
          "phone":this.phone
        }
      }).then(res => {
        console.log(res.data);
      }).catch(err => {
        console.log(err);
      })
    },
    regedit() {
      console.log("注册");
      axios({
        method: "get",
        url: "/regedit",
        params:{
          "phone":this.phone,
          "code":this.code
        }
      }).then(res => {
        console.log(res.data);
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.el-row {
  margin-bottom: 20px;
}

</style>
